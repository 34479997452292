











import { Component, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import CKEditor from 'ckeditor4-vue'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'VsCkeditor',
    components: {
        VsFullTopBarLayout,
        VsLoader,
        ckeditor: CKEditor.component,
    },
})
export default class extends Vue {
    $refs: any
    private value!: string
    private tags!: any[]
    private buttons!: any[]
    private showEditor = false
    private loading = false

    private initEditor (data: any) {
        this.value = data.value
        this.tags = data.tags
        this.buttons = data.buttons
        this.showEditor = true
        // this.$refs.dragoIframe.contentWindow.postMessage('saveProject', '*')
    }

    beforeMount () {
        const loader = document.getElementById('first-loader')
        if (loader) loader.style.display = 'none'
        window.addEventListener('message', this.receiveMessage)
    }

    mounted () {
        window.parent.postMessage('ready', '*')
    }

    beforeDestroy () {
        window.removeEventListener('message', this.receiveMessage)
    }

    get notFullPageParam () {
        return this.$route.query?.notFullPage || false
    }

    private receiveMessage (event: any) {
        try {
            const data = JSON.parse(event.data)
            this.actionsWithObjectMessage(data, event)
        } catch (e) {
            // data is not object
            this.actionsWithStringMessage(event)
        }
    }

    private actionsWithObjectMessage (objectMessage: any, event: any) {
        switch (objectMessage.name) {
            case 'init':
                this.initEditor(objectMessage.data)
        }
    }

    private actionsWithStringMessage (event: any) {
        switch (event.data) {
            case 'save':
                event.source.postMessage(JSON.stringify({
                    name: 'save',
                    value: this.$refs.ckeditor.instance.getData(),
                }), event.origin)
        }
    }

    // private editorConfig = {
    //     toolbarGroups: [
    //         { name: 'clipboard', groups: ['clipboard', 'undo'] },
    //         { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    //         { name: 'links' },
    //         { name: 'insert' },
    //         { name: 'forms' },
    //         { name: 'tools' },
    //         { name: 'document', groups: ['mode', 'document', 'doctools'] },
    //         '/',
    //         { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    //         { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
    //         { name: 'styles' },
    //         { name: 'colors' },
    //         { name: 'others' },
    //     ],
    //     extraPlugins: 'widget,codemirror,image2,templates,listbuttons,colorbutton,panelbutton,floatpanel,font,richcombo,listblock,panel,justify,button',
    //     removeButtons: 'Underline,Subscript,Superscript,Styles,About',
    //     format_tags: 'p;h1;h2;h3;div;pre',
    //     removeDialogTabs: 'image:advanced',
    // }

    get consoleUrl () {
        return AppModule.consoleBaseUrl
    }

    get publicPath () {
        return process.env.BASE_URL
    }

    onEditorReady (e: any) {
        e.focus()
    }

    onNamespaceLoaded (CKEDITOR: any) {
        const buttons = this.buttons
        const tags = this.tags
        const notFullPage = this.notFullPageParam
        CKEDITOR.plugins.add('listbuttons', {
            requires: ['richcombo'], //, 'styles' ],
            init: function (editor: any) {
                if (notFullPage) {
                    editor.config.fullPage = false
                }
                const config = editor.config
                const lang = editor.lang.format
                const plugin_path = this.path
                // Create style objects for all defined styles.

                const createRich = function (tags: any) {
                    editor.ui.addRichCombo(tags.title, {
                        label: tags.title,
                        title: tags.title,
                        voiceLabel: tags.title,
                        className: 'cke_listbuttons',
                        multiSelect: false,
                        isHTML: function (str: any) {
                            const a = document.createElement('div')
                            a.innerHTML = str
                            for (let c = a.childNodes, i = c.length; i--;) {
                                if (c[i].nodeType === 1) return true
                            }
                            return false
                        },

                        panel: {
                            css: [plugin_path + '/css/listbuttons-style.css', config.contentsCss, CKEDITOR.getUrl(CKEDITOR.skin.getPath('editor') + 'editor.css')],
                            voiceLabel: lang.panelVoiceLabel,
                        },

                        init: function () {
                            // this.add('value', 'drop_text', 'drop_label');
                            const editor_tags = tags.tags
                            for (const i in editor_tags) {
                                this.add(escape(editor_tags[i].tag), editor_tags[i].title, editor_tags[i].title)
                            }
                        },

                        onClick: function (value: any) {
                            let html: any = ''
                            if (this.isHTML(unescape(value))) {
                                const placeholder = document.createElement('div')
                                placeholder.innerHTML = unescape(value)
                                html = placeholder.firstElementChild?.outerHTML
                            } else {
                                html = unescape(value)
                            }
                            editor.focus()
                            editor.fire('saveSnapshot')
                            editor.insertHtml(html)
                            editor.fire('saveSnapshot')
                        },
                    })
                }

                for (const i in tags) {
                    createRich(tags[i])
                }

                // add two extra buttons for quick access
                const CommandConstruct: any = (html: any) => {
                    this.html = html
                    this.exec = function (ed: any) {
                        ed.focus()
                        ed.fire('saveSnapshot')
                        ed.insertHtml(this.html)
                        ed.fire('saveSnapshot')
                    }

                    return this
                }

                for (let i = 0; i < buttons.length; i++) {
                    const btn = buttons[i]

                    editor.addCommand(btn.command, new CommandConstruct(btn.html))

                    editor.ui.addButton(btn.name, {
                        label: btn.label,
                        command: btn.command,
                    })
                }
            },
        })
    }
}
